class ZIDXAccountLeadRoutes implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLeadRoutesContainer";
    }
    getMatchURL(){
        return "/account/lead-routes";
    }
    render(){
        // console.log("lead routes");
    }
}